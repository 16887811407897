import React, { useState, useEffect } from 'react';

const Countdown = (props) => {
  const { countdown } = props; 
  const [falling, setFalling] = useState(false);

  useEffect(() => {
    if (countdown > 0) {
      setFalling(true);
      const timeout = setTimeout(() => {
        setFalling(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [countdown]);

  return (
    <div className="text-6xl text-center font-mono select-none">
      {(countdown > 0) && <div className={`fall-animation ${falling ? 'falling' : ''}`}>{countdown}</div>}
    </div>
  );
};

export default Countdown;