const I18n = {
    lang: 'en',
    dict: {'en': {}},
    getlocale() { const locale =  navigator.language || navigator.browserLanguage || ( navigator.languages || [ "en" ]) [0]; 
                  return locale.startsWith('ru')? 'ru':'en'
                },
    getlang() { return this.lang },
    setlang(lang) { if (this.dict.hasOwnProperty(lang)) { this.lang = lang; } return this.lang },
    setdict(dict) { this.dict = dict },
    updatedict(dict) { this.dict = Object.assign(this.dict, dict) },
    make_dict_en(dict) { for (let k in dict) { this.dict.en[k] = k } },
    get(text)   { return this.dict[this.lang].hasOwnProperty(text)? this.dict[this.lang][text]: text },
    retranslate() {
            const strings = document.querySelectorAll(".translated");
            strings.forEach(function(item) {
                item.innerText = I18n.get(item.innerText)
            });
    }
 };

const dict = {
'ru': {
    'Tap to start': 'Cтарт',
    'Time': 'Время',
    'Short press to set period 1. Long - for period 2.' : "Короткое нажатие - установить время периода 1. Длинное - периода 2.",
    'You can install this application to work offline. Click "Share" and ':'Вы можете установить это приложение для работы в автономном режиме. Нажмите "Поделиться" и ',
    '"Add to Home Screen"': '"Добавить на экран "Домой""',
    '"Add to Main Screen"': '"Добавить на главный экран"',
    'This is a mobile App. Please use an Android/iOS device.': 'Это мобильное приложение. Пожалуйста, используйте Android/iOS.',
    'Long tab - Tabata mode': 'Долгое нажатие - режим Tabata',
}
};
I18n.updatedict(dict);
I18n.make_dict_en(dict.ru);
I18n.setlang(I18n.getlocale())

export default I18n;