import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import useInterval from './useInterval';
import Countdown from './Countdown';
import useLongPress from './useLongPress';
import I18n from './I18n';

const timeList = [10, 20, 30, 40, 50, 60, 90, 120, 180, 240, 300, 360, 420, 480, 540, 600, 900, 1200];
const btnList = [3, 5, 10, 20, 30, 60];

const tabataWorkTime = 20;
const tabataRestTime = 10;

I18n.setlang(I18n.getlocale())

const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  }
  else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
    return "iOS"
  }
  return "Other"
}

const isMobile = (getMobileOS() !== "Other");
const installHelper = (getMobileOS() === "iOS")? I18n.get('"Add to Home Screen"') : I18n.get('"Add to Main Screen"');

function variants (time, setTime, curTimeRef, firstLaunch) {
  return timeList.map((v) => {
    return <div key={v} >
     {(time==v) && <div ref={curTimeRef}/>}
     {firstLaunch && (time===v) && <div className="tooltip tooltip-open tooltip-up tooltip-success z-20" data-tip={I18n.get('Time')}/> }
      <div className={"block px-4 py-2 select-none"+((time==v)?" text-blue-400 font-bold":"")} onClick={()=>{setTime(v); localStorage.setItem('time', v.toString());}}>{v}</div>
    </div>
  })
}



function App() {

  const wakeLockRef = useRef(null);
  const [maxTime, setMaxTime] = useState((localStorage.getItem('time')===null)? timeList[5]: parseInt(localStorage.getItem('time')));
  const [time, setTime] = useState((localStorage.getItem('time')===null)? timeList[5]: parseInt(localStorage.getItem('time')));
  const [soundTime1, setSoundTime1] = useState((localStorage.getItem('sndtime1')===null)? btnList[2]: parseInt(localStorage.getItem('sndtime1')));
  const [soundTime2, setSoundTime2] = useState(localStorage.getItem('sndtime2'));
  const [firstLaunch, setFirstLaunch] = useState((localStorage.getItem('firstLaunch')===null));
  const [go, setGo] = useState(false);
  const [tabata, setTabata] = useState(false);
  const [tabataWork, setTabataWork] = useState(false);
  const [showCountDownText, setShowCountDownText] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);
  const [disablePress, setDisablePress] = useState(false);
  const audioRef = useRef(null);
  const audioRefTab = useRef(null);
  const audioRefSec = useRef(null);

  const curTimeRef = useRef();
  const curTimeCountainerRef = useRef();

  useEffect(() => {
    const enableWakeLock = async () => {
      try {
        const wakeLock = await navigator.wakeLock.request('screen');
        wakeLockRef.current = wakeLock;
      } catch (error) {
        //console.error('Failed to enable wake lock:', error);
      }
    };

    enableWakeLock();

    return () => {
      // Release wake lock when component unmounts
      if (wakeLockRef.current) {
        wakeLockRef.current.release();
        //console.log('Wake lock released.');
      }
    };
  }, []);

  useEffect(() => {
    if (firstLaunch) { localStorage.setItem('firstLaunch', ''); }
  }, [firstLaunch]);


  useEffect(() => {
    const containerElement = curTimeCountainerRef.current;
    const targetElement = curTimeRef.current;

    if (containerElement && targetElement) {
      const containerHeight = containerElement.offsetHeight;
      const targetOffsetTop = targetElement.offsetTop;
      const targetHeight = targetElement.offsetHeight;

      const scrollPosition =
        targetOffsetTop -
        containerElement.offsetTop -
        (containerHeight - targetHeight) / 2;

      containerElement.scrollTop = scrollPosition;
    }

  }, []);


  useInterval(() => {
    if (go) { setTime(time - 1); }
  }, go?1000:null);
 
  
  useEffect(() => {
    if (tabata) {
      if (tabataWork) {
        if (time === 0) {
          setTime(tabataRestTime);
          setTabataWork(false);
        }
      } else {
        if (time === 0) {
          setTime(tabataWorkTime);
          setTabataWork(true);
        } 
      }
  
    }
    else if (go) {
      if (time === 0) { 
        audioRef.current.src="finalbeep.mp3";  
        audioRef.current.play();
        setGo(false); 
      }
      else if (((time % soundTime2) === 0)) {
        audioRef.current.src="2beep.mp3";
        audioRef.current.play();
      }
      else if ((time % soundTime1) === 0) {
        audioRef.current.src="1beep.mp3";
        audioRef.current.play();
      } else {
        //audioRef.current.src="1sec.mp3";
        audioRefSec.current.play();
      }
   }
  }, [time]);

  useEffect(() => {
    if (soundTime1 !== null) {
      localStorage.setItem('sndtime1', soundTime1.toString());
    }
  }, [soundTime1]);

  useEffect(() => {
    if (soundTime2 !== null) {
      localStorage.setItem('sndtime2', soundTime2.toString());
    }
  }, [soundTime2]);

  useEffect(() => {
    if (!go) {return;}
    if (tabataWork) {
      audioRefTab.current.play();
      setMaxTime(tabataWorkTime);
      setTime(tabataWorkTime);
    } else {
      setMaxTime(tabataRestTime);
      setTime(tabataRestTime);
    }
  }, [tabataWork]);


  /*
  const lblist0 = btnList.map((v)=>{
    const lb = useLongPress(
      ()=>{ if (v === soundTime2)  { setSoundTime2(999); } else if (v !== soundTime1) { setSoundTime2(v) }},
      ()=>{ if (v === soundTime1)  { setSoundTime1(999); } else if (v !== soundTime2) { setSoundTime1(v) }},
      {shouldPreventDefault: true, delay: 400 }
    );
    return lb;
  })
  */

  const lb = useLongPress(
    ()=>{ handleFlip(); },
    ()=>{ 
      if (tabata){
        setTabataWork(!go? true: false); 
        audioRef.current.pause(); audioRef.current.currentTime = 0; 
        audioRefTab.current.pause(); audioRefTab.current.currentTime = 0;
      } else {
        if (!go) {setTime(maxTime-1); }
      }; 
      setGo(!go); 
    },
    {shouldPreventDefault: true, delay: 800 }
  );
  const lb1 = useLongPress(
    ()=>{ if (btnList[0] === soundTime2)  { setSoundTime2(999); } else if (btnList[0] !== soundTime1) { setSoundTime2(btnList[0]) }},
    ()=>{ if (btnList[0] === soundTime1)  { setSoundTime1(999); } else if (btnList[0] !== soundTime2) { setSoundTime1(btnList[0]) }},
    {shouldPreventDefault: true, delay: 400 }
  );
  const lb2 = useLongPress(
    ()=>{ if (btnList[1] === soundTime2)  { setSoundTime2(999); } else if (btnList[1] !== soundTime1) { setSoundTime2(btnList[1]) }},
    ()=>{ if (btnList[1] === soundTime1)  { setSoundTime1(999); } else if (btnList[1] !== soundTime2) { setSoundTime1(btnList[1]) }},
    {shouldPreventDefault: true, delay: 400 }
  );
  const lb3 = useLongPress(
    ()=>{ if (btnList[2] === soundTime2)  { setSoundTime2(999); } else if (btnList[2] !== soundTime1) { setSoundTime2(btnList[2]) }},
    ()=>{ if (btnList[2] === soundTime1)  { setSoundTime1(999); } else if (btnList[2] !== soundTime2) { setSoundTime1(btnList[2]) }},
    {shouldPreventDefault: true, delay: 400 }
  );
  const lb4 = useLongPress(
    ()=>{ if (btnList[3] === soundTime2)  { setSoundTime2(999); } else if (btnList[3] !== soundTime1) { setSoundTime2(btnList[3]) }},
    ()=>{ if (btnList[3] === soundTime1)  { setSoundTime1(999); } else if (btnList[3] !== soundTime2) { setSoundTime1(btnList[3]) }},
    {shouldPreventDefault: true, delay: 400 }
  );
  const lb5 = useLongPress(
    ()=>{ if (btnList[4] === soundTime2)  { setSoundTime2(999); } else if (btnList[4] !== soundTime1) { setSoundTime2(btnList[4]) }},
    ()=>{ if (btnList[4] === soundTime1)  { setSoundTime1(999); } else if (btnList[4] !== soundTime2) { setSoundTime1(btnList[4]) }},
    {shouldPreventDefault: true, delay: 400 }
  );
  const lb6 = useLongPress(
    ()=>{ if (btnList[5] === soundTime2)  { setSoundTime2(999); } else if (btnList[5] !== soundTime1) { setSoundTime2(btnList[5]) }},
    ()=>{ if (btnList[5] === soundTime1)  { setSoundTime1(999); } else if (btnList[5] !== soundTime2) { setSoundTime1(btnList[5]) }},
    {shouldPreventDefault: true, delay: 400 }
  );
  const lblist = [lb1, lb2, lb3, lb4, lb5, lb6];

  function btns () {
    return btnList.map((v, i) => {
      return <button key={v} {...lblist[i]} className={"text-xl select-none "+ ((soundTime1==v)?"active font-bold text-info": ((soundTime2==v)?"active font-bold text-secondary":""))+((!isMobile)?" btn btn-outline btn-square btn-lg lg:btn-md":"")}>
              {v}
            </button>
    })
  };

  const handleFlip = () => {
    if (tabata) { //flipping from tabata to normal 
      audioRefTab.current.pause(); audioRefTab.current.currentTime = 0;
      setMaxTime((localStorage.getItem('time')===null)? timeList[5]: parseInt(localStorage.getItem('time')));
      setTime((localStorage.getItem('time')===null)? timeList[5]: parseInt(localStorage.getItem('time')));
      setTabataWork(false);
    } else {
      audioRef.current.pause(); audioRef.current.currentTime = 0;
    }

    setShowCountDownText(false); // Reset the showText state
    setTabata(prev => !prev);
    setIsFlipped(false);
    setGo(false);
    setDisablePress(true);

    setTimeout(() => {
      setIsFlipped(true);
    }, 50); // Delay flipping to allow resetting of animation state
  };

  const handleAnimationEnd = () => {
    setShowCountDownText(true);
    setTimeout(() => {
      setDisablePress(false);
    }, 200); // Delay flipping to allow resetting of animation state
  };
//
  const body = <>
   { firstLaunch && <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-zinc-900 bg-opacity-60 z-20 overflow-y-auto min-h-80" onClick={()=>setFirstLaunch(false)}/>}

      <audio ref={audioRef} src="1beep.mp3" type="audio/mpeg"/>
      <audio ref={audioRefTab} src="tabata.mp3" type="audio/mpeg"/>
      <audio ref={audioRefSec} src="1sec.mp3" type="audio/mpeg"/>

      { firstLaunch && <div className="tooltip tooltip-open tooltip-bottom tooltip-success z-20" data-tip={I18n.get("Tap to start")}/>}
      <div className='w-5/6 lg:w-3/6 aspect-square text-blue-400 mt-6 select-none'>  
               <div className={`radial-progress w-full h-full active:bg-blue-600 transform transition duration-500 ${isFlipped?"animate-flip":""} ${tabata?"bg-blue-600":""} ${disablePress?"pointer-events-none":""}`} 
        onAnimationEnd={()=>handleAnimationEnd()} style={{'--value': go?time*100/maxTime:100}} {...lb}>
          { go?
            <div className="container mx-auto">
             <Countdown countdown={time} />
            </div>
          : <span className={'select-none text-6xl md:text-4xl'}>{showCountDownText?(tabata?"Tabata":"Go!"):""}</span>}
          </div>
      </div>
      { firstLaunch && <div className="tooltip tooltip-open tooltip-top tooltip-success z-20" data-tip={I18n.get("Long tab - Tabata mode")}/>}

      { firstLaunch &&  <div className="tooltip tooltip-open tooltip-bottom tooltip-success z-20" data-tip={I18n.get('You can install this application to work offline. Click "Share" and ')+installHelper+"."}/>}
      <div className={`flex flex-col overflow-y-auto no-scrollbar h-1/4 lg:h-96 w-full mt-8 whitespace-nowrap text-center text-2xl ${tabata ?"pointer-events-none opacity-20":""}`} ref={curTimeCountainerRef} style={{ overflowY: 'scroll'}}>
          {variants(maxTime, setMaxTime, curTimeRef, firstLaunch)}
      </div>
      <div className='grow'></div>
      { firstLaunch &&  <div className="tooltip tooltip-open tooltip-up tooltip-success z-20 mb-24 lg:mb-12" data-tip={I18n.get("Short press to set period 1. Long - for period 2.")}/>}
      { isMobile ? <div className={`btm-nav ${tabata ?"pointer-events-none opacity-20":""}`}>{btns()}</div>
      : <div className={`flex flex-row w-full justify-around mt-6 ${tabata ?"pointer-events-none opacity-20":""}`}>{btns()}</div>
      }

  </>

/*
  if (getMobileOS()==='Other') {
    return (
    <div className="bg-repeat p-2 bg-base-200 w-screen h-screen flex flex-col justify-center items-center" style={{backgroundImage: "radial-gradient(hsla(var(--bc) /.2) .5px, hsla(var(--b2) /1) .5px)", backgroundSize: "5px 5px"}}>
       <div class='mockup-phone w-1/2'>
        <div class="camera"></div> 
        <div class="display bg-base-100">
          <Main/>
        </div>
      </div>    </div>
    )
  }
*/
return (
  <div className="bg-repeat p-2 bg-base-200 w-screen h-screen flex flex-col justify-start items-center" style={{backgroundImage: "radial-gradient(hsla(var(--bc) /.2) .5px, hsla(var(--b2) /1) .5px)", backgroundSize: "5px 5px"}}>
  {isMobile? body:
   <div className='mockup-phone w-1/3 p-6'>
   <div className="camera"></div> 
   <div className="display bg-base-100 flex flex-col items-center justify-center p-6">
     {body}
   </div>
 </div>    
  }
</div>

)
}

export default App;
